import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

import e404 from "../assets/img/ikonki/strona-bledu/404.svg"

import "../../i18n"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return <></>
  }

  return (
    <Layout>
      <Seo title={t("seo-title-404")} robots={"noindex, nofollow"} />
      <div className="error-page">
        <div className="error-content">
          <p className="text-right">{t("404-header")}</p>
          <p>
            <img src={e404} alt={t("404-header")} className="error-image" />
          </p>
          <p className="text-center">{t("404-message")}</p>
          <p className="text-center">
            <br />
            <Link to={t("link-url-home")} className="btn btn-primary">
              {t("404-home-button")}
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
